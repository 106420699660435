.topline {
    height: 5px;
    background-color: @primary-orange;
    border-bottom: 10px solid @primary-orange;
    margin: auto;
}

#page_container {
    border: 1px solid #e7e7e7;
    height: 100%;
    .navbar-brand {
        padding: 3px 15px;
    }
    .navbar-right {
        .wrapper {
            margin-right: 30px;
            ul {
                list-style: none;
                float: left;
                margin: 0;
                padding-left: 0;
                > li {
                    float: left;
                    display: block;
                    > a {
                        padding-top: 15px;
                        padding-bottom: 15px;
                        padding-left: 33px;
                        vertical-align: bottom;
                        line-height: 50px;
                        font-size: 14px;
                        color: #333333;
                    }
                }
            }
        }
    }
    ul.navbar-nav {
        >li {
            >a {
                padding-top: 5px;
                padding-bottom: 0px;
                padding-left: 10px;
                font-size: 16px;
                vertical-align: -8%;
                line-height: 50px;
                color: #333333;
                >i {
                    background-image: url('../images/header/main_menu_02.png');
                    margin-right: 5px;
                    height: 50px;
                    width: 50px;
                    line-height: 1;
                    text-rendering: auto;
                    display: inline-block;
                    text-align: center;
                    vertical-align: middle;
                    &.news {
                        background-position: 0 0;
                    }
                    &.about_us {
                        background-position: -55px 0;
                    }
                    &.eduction {
                        background-position: -105px 0;
                    }
                    &.domain {
                        background-position: -160px 0;
                    }
                    &.works {
                        background-position: -210px 0;
                    }
                    &.franchise {
                        background-position: -265px 0;
                    }
                    &.goods {
                        background-position: -320px 0;
                    }
                }
                span {}
            }
            &.dropdown {
                &:hover {
                    display: block;
                    margin-top: 0;
                    opacity: 1;
                    .dropdown-menu {
                        display: block;
                        margin-top: 0;
                        opacity: 1;
                    }
                }
                .dropdown-menu {
                    -webkit-transition: all 0.3s;
                    -moz-transition: all 0.3s;
                    -ms-transition: all 0.3s;
                    -o-transition: all 0.3s;
                    transition: all 0.3s;
                    opacity: 0;
                }
            }
        }
    }
    .hr {
        margin-top: 0;
        margin-bottom: 5px;
    }
}

p.text-indent {
    text-indent: 36px;
}

.outline {
    .media {
        margin-top: 20px;
        margin-bottom: 20px;
        .media-heading {
            border-bottom: 1px solid #777;
        }
        .media-body {
            padding-left: 20px;
        }
    }
}

.overview {
    .thumbnail {
        margin-bottom: 0px;
    }
    h3.subject {
        margin-top: 5px;
        padding-top: 0px;
    }
}
