#footer {
    position: relative;
    font-size: 12px;
    background-color: #f68121;
    .content {
        height: 380px;
        background-image: url('../images/bg/footer.png');
        background-repeat: no-repeat;
        position: relative;
        z-index: 999;
    }
    .info {
        height: 319px;
        position: relative;
        .address {
            color: #ffffff;
            position: absolute;
            top: 100px;
            left: 80px;
            line-height: 20px;
            a {
                color: #fff;
                cursor: pointer;
            }
        }
    }
    .note {
        padding-top: 30px;
        color: #d4d4d4;
        .copyright {
            padding-left: 30px;
        }
        .browser {
            padding-right: 40px;
        }
    }
    .bar {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 62px;
        background-color: #666359;
        z-index: 990;
    }
    .box {
        position: relative;
        form {
            position: absolute;
            top: 80px;
            left: 40px;
            width: 200px;
            .form-group {
                margin: 0px 5px;
            }
            label {
                width: 60px;
                display: block;
                line-height: 30px;
                &.radio-inline {
                    display: inline-block;
                    width: 30px;
                    padding: 0px;
                    line-height: 20px;
                }
            }
            .field {
                width: 120px;
                .form-control {
                    height: 25px;
                    border-radius: 0px;
                    box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.3) inset;
                    padding: 2px;
                }
            }
            .btn {
                padding: 3px 12px;
                border-radius: 0px;
            }
            .btn-orange {
                background-color: @primary-orange;
                color: #fff;
            }
            .btn-gray {
                background-color: #9D9D9D;
                color: #fff;
            }
        }
    }
}
