@import './bower_components/font-awesome/less/font-awesome.less';
@import './bower_components/bootstrap/less/bootstrap.less';
@import (inline) './../css/jquery-colorbox/colorbox.css';
@import './web/variables.less';
@import './web/nav.less';
@import './web/menu.less';
@import './web/content.less';
@import './web/advert.less';
@import './web/youtube.less';
@import './web/breabcrumbs.less';
@import './web/sidebar.less';
@import './web/news.less';
@import './web/footer.less';
@import './web/travel.less';
@import './web/layout.less';
@import './web/sitemap.less';
@import './web/auth.less';

body {
    font-family: "Microsoft JhengHei";
    font-size: 16px;
    line-height: 1.6;
    color: #333333;
    min-width: 1170px;
}



// @media only screen and (min-device-width: 320px) and (max-device-width: 667px) {
//     body {
//         width: 970px !important;
//     }
// }

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
	.container {
        width: 1150px;
    }
}
