.main_menu {
    margin-top: -25px;
    z-index: 100;
    position: relative;
    zoom: 1;
    .bg {
        background-image: url('../images/bg/superstar.png');
        background-repeat: no-repeat;
        margin: 0 15px;
        border-bottom: 2px solid @gray-lighter;
    }
    a.brand {
        width: auto;
        height: 205px;
        display: block;
        span {
            visibility: hidden;
        }
    }
    .menu {
        text-align: right;
        ul.nav {
            margin-top: 80px;
            list-style: none;
            display: inline-block;
            & > li {
                display: block;
                position: relative;
                float: left;
                a {
                    background-image: url('../images/header/main_menu_04.png');
                    background-repeat: no-repeat;
                    height: 92px;
                    width: 92px;
                    text-align: center;
                    color: #767675;
                    span {
                        margin-top: 75px;
                        display: block;
                        font-size: 14px;
                        font-weight: bold;
                        padding-top: 5px;
                    }
                }
                a.news {
                    background-position: -10px 0px;
                }
                a.about_us {
                    background-position: -115px 0px;
                }
                a.eduction {
                    background-position: -215px 0px;
                }
                a.domain {
                    background-position: -320px 0px;
                }
                a.works {
                    background-position: -425px 0px;
                }
                a.franchise {
                    background-position: -530px 0px;
                }
                a.goods {
                    background-position: -630px 0px;
                }
            }
            li.active {
                a {
                    color: #ee8d36;
                }
            }
        }
    }
}


