.container {
    .jumbotron {
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 1px dashed #F09BB0;
        background-color: #fff;
        padding: 0 0 30px;
    }
    .btn-pink {
    	background-color: #F09BB0;
    	color: #fff;
    }
}
