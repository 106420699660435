.ad_bar {
    padding-bottom: 10px;
    &.login {
        position: relative;
        form {
            position: absolute;
            top: 40px;
            left: 40px;
            width: 200px;
            label {
                color: #fff;
                width: 60px;
                display: block;
                line-height: 30px;
            }
            .field {
                width: 120px;
                padding-top: 5px;
                .form-control {
                    height: 25px;
                    border-radius: 0px;
                    box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.3) inset;
                    padding: 2px;
                }
            }
            .form-group {
                margin: 0px 5px;
                .btn {
                    margin-top: 5px;
                    margin-left: 70px;
                    width: 80px;
                    border-radius: 0px;
                    padding: 3px 12px;
                }
            }
        }
    }
}
