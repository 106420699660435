.sidebar {
    padding-top: 10px;
    padding-bottom: 50px;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    min-height: 1px;
    z-index: 1;
    .inner_sidebar {
        margin-right: -30px;
        margin-left: -15px;
        a {
            padding-left: 30px;
            line-height: 50px;
            margin-top: -1px;
            padding-top: 1px;
            padding-bottom: 0px;
            width: 100%;
            color: #333333;
        }
        .nav_header {
            border-top: 1px solid #e0e0e0;
            >label {
                padding-left: 30px;
                line-height: 50px;
                margin-top: -1px;
                padding-top: 1px;
                width: 100%;
                color: #333333;
                border-bottom: 1px solid #e0e0e0;
            }
            ul {
                &.nav {
                    margin-left: 20px;
                    padding-left: 20px;
                }
            }
        }
        ul {
            >li {
                a {
                    &.focus {
                        color: #ee8d36;
                        font-weight: bold;
                    }
                }
                border-bottom: 1px solid #e0e0e0;
                &.active {
                    a {
                        color: #ee8d36;
                        background-color: #eeeeee;
                    }
                }
            }
        }
        i {
            color: @primary-orange;
        }
    }
}
