#travel {
    .carousel {
        .carousel-inner {
            .img-wrapper {
                padding: 4px;
                border: 1px solid #ddd;
                border-radius: 4px;
                transition: border 0.2s ease-in-out;
            }
        }
    }
    .subject {
        text-shadow: 1px 1px #fff;
        border-bottom: 1px dashed #393939;
        width: 100%;
        margin: 0 auto;
        line-height: 1.4;
        margin-bottom: 10px;
    }
    .description {
        margin-top: 15px;
    }
    .panel {
    	background: transparent;
    }
    .list-group {
        .list-group-item {
            border: 2px solid #ddd;
            text-align: center;
            p {
            	font-weight: bold;
            }
        }
    }
    .btn-warning {
    	color: #fff;
    }
}
