.content_wrapper {
    .tile {
        background - image: url('../images/bg/tile.png');
        line-height: 40px;
        padding-left: 20px;
        color: #555;
    }
}

.page_content {
    .wrapper {
        border-left: 1px solid #e0e0e0;
        min-height: 400px;
        padding: 0px 20px;
        .video {
            padding-top: 10px;
            .note {
                padding: 5px 15px;
                h4 {
                    line-height: 1.4;
                }
            }
        }
        .youtubes {
            margin-top: 10px;
        }
    }
    a {
        color: #E56202;
        text-decoration: none;
    }
    .outline {
        word-wrap: break-word;
        min-height: 500px;
        padding: 10px 0px 200px 0px;
        margin-left: auto;
        margin-right: auto;
        .content {
            min-height: 400px;
            padding: 5px 30px 0px 30px;
            h3 {
                line-height: 1.4;
            }
        }
    }
    .bg_star_left {
        background-image: url('../images/bg/left_star.png');
        background-repeat: no-repeat;
        background-position: left bottom;
        background-size: 100%;
    }
    .bg_star_right {
        background-image: url('../images/bg/right_star.png');
        background-repeat: no-repeat;
        background-position: left bottom;
        background-size: 100%;
    }
    figure {
        text-align: center;
        padding: 10px;
        margin: 10px 20px;
        display: inline-block;
        img {
            margin: 0 auto;
        }
    }
    > figcaption {
        text-align: center;
        display: block;
        /* For IE8 */
        font-size: 12px;
    }
    .img-responsive {
        display: inline-block;
    }
}

.pagination {
    >li {
        > a {
            border-radius: 30px;
        }
        > span {
            border-radius: 30px;
        }
    }
}


