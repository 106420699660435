.sitemap {
    padding: 10px 20px;
    min-height: 500px;
    ul.nav {
        a {
            color: #666666;
        }
        padding-left: 15px;
        >li {
            label {
                padding: 10px 15px;
            }
        }
    }
}
