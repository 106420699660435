.nav_top {
    position: relative;
    background-color: @primary-orange;
    .relation_link {
        height: 48px;
        a {
            color: #fff;
            padding: 5px;
            margin-left: 10px;
            &.language {
                line-height: 30px;
                padding-left: 10px;
                padding-right: 10px;
            }
            &.mobile {
                padding-left: 15px;
                padding-right: 15px;
            }
            &:focus {
                background: none;
                color: #fff;
            }
            &:hover {
                background: #fff;
                color: @primary-orange;
            }
        }
    }
    .row {
        background-image: url('../images/bg/top_line.png');
        background-repeat: no-repeat;
        background-position: right bottom;
    }
}


