.pages_title {
    background-color: #fffce4;
    border-top: 1px solid #c8c8c8;
    border-bottom: 1px solid #c8c8c8;
    .breadcrumb {
        background-color: #fffce4;
        margin-bottom: 0;
        font-size: 13px;
        padding: 15px 15px;
        li {
            color: #999999;
            span {
                padding-left: 5px;
            }
        }
        >li + li:before {
            content: "";
            color: #cccccc;
            padding: 5px 0 5px 5px;
        }
    }
}
