.news {
    h4 {
        color: #f37021;
        font-weight: bold;
        a {
        	color: #f37021;
        }
    }
    p {
    	a {
    		color: #555;
    	}
    }
}
