.youtube {
    .intro {
        max-height: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .container {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        .video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
